const themes = [
  "monokai",
  "eclipse",
  "ambiance",
  "dracula",
  "material-darker",
  "material-palenight",
  "mdn-like",
];

export default themes;
